<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { onMounted,ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import { shortDateWithTimeTz } from "../../../utils/dateUtils";

import { AgGridVue } from "ag-grid-vue3";
import { useThemeStore } from "@/stores/themeStore";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";
import { connectToServerApi } from "@/utils/agGridUtils";
import { LicenseManager } from "ag-grid-enterprise";
import useOutboundOrders from "@/hooks/useOutboundOrders";
import OutboundOrderLayout from "@/layouts/OutboundOrderLayout.vue";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { outboundOrder, fetchOutboundOrder } = useOutboundOrders();

const userStore = useUserStore();
const route = useRoute();
const themeStore = useThemeStore();

let gridApi;
let context = ref(null);

const OUTBOUND_ORDERS_INVENTORY_TRANSACTIONS_URL = `/outbound_orders/${route.params.id}/inventory_transactions?expand=inventory_transaction_reason,warehouse,product`;

const advancedFilterKeys = ["id", "item_id", "created_at", "warehouse.nickname", "qty", "inventory_transaction_reason.name", "created_by.name", "notes"];

onMounted(async () => {
  await fetchOutboundOrder(route.params.id).then(() => {
    setPageTitle(`Outbound Order #OUT-${outboundOrder.value.id}`);
  });
});

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: OUTBOUND_ORDERS_INVENTORY_TRANSACTIONS_URL,
    advancedFilterKeys: advancedFilterKeys,
  }
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const defaultColDef = getDefaultColDefs();

const columnDefs = [
  { field: "id", minWidth: 50, headerName: "ID", colId: "id", cellDataType: "text"},
  {
      field: "created_at", minWidth: 210, headerName: "DATE", colId: "created_at", cellDataType: "text", valueGetter: (params) => {
          return shortDateWithTimeTz(params.data.created_at);
      }
  },
  { field: "item_id", minWidth: 50, headerName: "ITEM #", colId: "item_id", cellDataType: "text", width: "300" },
  { field: "product.sku", minWidth: 200, headerName: "SKU", colId: "product.sku", cellDataType: "text", width: "300",
    cellRenderer: function (params) {
      if (params.data.product.sku) {
        return `<a href="/products/${params.data.product_id}">${params.data.product.sku}</a>`;
      } else {
        return '';
      }
    },
  },
  { 
    field: "inventory_transaction_reason.name", minWidth: 50, headerName: "TYPE", colId: "inventory_transaction_reason.name", width: "300",
    valueGetter: (params) => {
      return params.data.inventory_transaction_reason.name.toUpperCase();
    }
  },
  { field: 'qty', minWidth: 120, headerName: "QUANTITY", colId: "qty", cellDataType: "text", width: "300",
    valueGetter: (params) => {
      return params.data.qty + " " + params.data.product.default_unit;
    }
  },
  { field: "warehouse.nickname", minWidth: 150, headerName: "WAREHOUSE", colId: "warehouse.nickname", cellDataType: "text",
    cellRenderer: function (params) {
      if (params.data.warehouse.nickname) {
        return `<a href="/warehouses/${params.data.warehouse_id}">${params.data.warehouse.nickname}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: "created_by.name", minWidth: 200, headerName: "USER", colId: "created_by.name", cellDataType: "text", width: "300",
    cellRenderer: function (params) {
      if (params.data.created_by.name) {
        return `<a href="/users/${params.data.created_by.id}">${params.data.created_by.name}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: "notes", minWidth: 150, headerName: "NOTES", colId: "notes", cellDataType: "text" },
];

const gridOptions = getDefaultGridOptions(columnDefs);

</script>

<template>
  <OutboundOrderLayout :outboundOrder="outboundOrder" v-if="outboundOrder">
    <div v-if="userStore.user && outboundOrder" class="px-4">
      <div class="w-100 px-2">
        <div class="w-100">
          <OrderHeader
            orderType="outbound"
            :orderId="outboundOrder.id"
            :status="outboundOrder.order_status.short_label"
          />
        </div>

        <v-card class="mt-4 pa-2 w-auto" elevation="0">
          <v-card-title class="text-h5">Inventory Transactions</v-card-title>
        </v-card>

        <div class="outbound-inventory-table">
          <AgGridVue 
            style="width: 100%; height: 100%"
            :class="themeStore.agTheme"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :context="context"
          ></AgGridVue>
        </div>
      </div>
    </div>
  </OutboundOrderLayout>
</template>

<style scoped>
.outbound-inventory-table {
  height: 670px;
  margin-top: 15px;
}
</style>